import { Component, Self } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'qr-zoom',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './qr-zoom.component.html',
  styleUrl: './qr-zoom.component.scss',
})
export class QrZoomComponent {
  public modalComponent?: ModalComponent<Self>;
  public initialData: any = {};
  close() {
    this.modalComponent?.close();
  }
}
