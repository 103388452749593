import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ThemeService } from '../services/api/theme.service';
import { map } from 'rxjs';
import { DOCUMENT } from '@angular/common';

export const themeResolver: ResolveFn<boolean> = (route, state) => {
  const themeService = inject(ThemeService);
  const doc = inject(DOCUMENT);
  return themeService.getThemeConfig().pipe(
    map((themeConfig) => {
      const styleEl = doc.createElement('style');
      styleEl.innerHTML =
        ':root{' +
        Object.keys(themeConfig)
          .map((key) => `--${key}: ${themeConfig[key]}`)
          .join(';\n') +
        '}';
      document.head.append(styleEl);
      themeService.themeApplied.set(true);
      return true;
    })
  );
};
