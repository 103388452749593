import {
  AfterViewInit,
  Directive,
  ElementRef,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { environment } from '../environments/environment';

@Directive({
  selector: '[image]',
  standalone: true,
})
export class ImageDirective implements OnChanges, AfterViewInit {
  protected elRef = inject<ElementRef<HTMLImageElement>>(ElementRef);

  @Input() src = '';
  isLoading = true;

  cdn = environment.cdn;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.elRef.nativeElement.src = this.src
      ? this.src?.startsWith('http') || this.src?.startsWith('data')
        ? this.src
        : `${environment.cdn}${this.src}`
      : '/assets/images/placeholder.jpg';
  }
  ngAfterViewInit(): void {
    this.elRef.nativeElement.onerror = () => {
      this.elRef.nativeElement.src = '/assets/images/placeholder.jpg';
    };
  }
}
