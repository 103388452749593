import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  inject,
  signal,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { StickyMobileMenuComponent } from '../modules/sticky-mobile-menu/sticky-mobile-menu.component';
import { MegaMenuComponent } from '../modules/mega-menu/mega-menu.component';
import { BreakpointObserver } from '../services/breakpoint.observer';
import { AsyncPipe } from '@angular/common';
import { CurrentCityService } from '../services/current-city.service';
import {
  debounceTime,
  filter,
  of,
  Subject,
  Subscription,
  switchMap,
  take,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { FooterComponent } from '../modules/footer/footer.component';
import { NavigationService } from '../services/navigation.service';
import { AuthService } from '../services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { AFF, FACEBOOK, GOOGLE, REF } from '../constants/storage.keys';
import { environment } from '../environments/environment';
import { GtmService } from '../services/gtm.service';
import { LayoutService } from '../services/layout.service';
import { UserService } from '../services/user.service';
import { StringHelper } from '../helpers/string.helper';
import { CookieMessageComponent } from '../modules/cookie-message/cookie-message.component';
import { LoadingService } from '../services/loading.service';
import { SkeletonComponent } from '../modules/skeleton/skeleton.component';
import { TranslateModule } from '@ngx-translate/core';
import { BackButtonDirective } from '../directives/back-button.directive';
import { ThemeService } from '../services/api/theme.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    StickyMobileMenuComponent,
    MegaMenuComponent,
    AsyncPipe,
    FooterComponent,
    CookieMessageComponent,
    SkeletonComponent,
    TranslateModule,
    BackButtonDirective,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [BreakpointObserver],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = 'biletzero.com';
  protected breakpointObserver = inject(BreakpointObserver);
  public isSmallScreen$ = this.breakpointObserver.isSmallScreen$;
  protected currentCityService = inject(CurrentCityService);
  protected navigationService = inject(NavigationService);
  protected activatedRoute = inject(ActivatedRoute);
  protected authService = inject(AuthService);
  protected cookieService = inject(CookieService);
  protected gtmService = inject(GtmService);
  protected userService = inject(UserService);
  protected router = inject(Router);
  protected layoutService = inject(LayoutService);
  protected loadingService = inject(LoadingService);
  public themeService = inject(ThemeService);
  protected el = inject(ElementRef);
  public layoutData = this.layoutService.layoutData;
  protected arSubs: Subscription;
  protected unsubs = new Subject();
  public showCookieMsgComp = signal(false);
  public showLoading$ = this.loadingService.showLoading$.pipe(
    debounceTime(150)
  );
  public env = environment;
  public isNavigationEnd = signal(false);
  public isThemeApplied = this.themeService.themeApplied;
  ngOnInit(): void {
    this.authService.initFirebaseTokenChangeListener();
    this.authService.accessToken$
      .pipe(
        switchMap((token) => {
          return token ? this.userService.user$ : of(null);
        }),
        tap((data) => {
          if (!data) {
            const guest =
              this.cookieService.get('guest') || StringHelper.generateGUID();
            this.userService.guestId = guest;
          }
        }),
        takeUntil(this.unsubs.asObservable())
      )
      .subscribe();
    this.currentCityService.findCurrentCity();
    this.checkQueryParams(
      Object.fromEntries(new URLSearchParams(location.search))
    );
    this.arSubs = this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.checkQueryParams(queryParams);
    });
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd || event instanceof NavigationStart
        ),
        tap((event) => {
          if (event instanceof NavigationEnd) {
            this.isNavigationEnd.set(true);
            this.el.nativeElement.classList.remove('!min-h-[200svh]');
          } else {
            this.isNavigationEnd.set(false);
          }
        }),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event) => {
        const queryString = event.url.split('?')[1] || '';
        const ref = this.cookieService.get(REF);
        const aff = this.cookieService.get(AFF);
        const fbclid = sessionStorage.getItem(FACEBOOK);
        const gclid = sessionStorage.getItem(GOOGLE);
        this.gtmService.push({
          event: 'vPageView',
          pagePath: event.url.split('?')[0],
          ...(queryString
            ? {
                queryParams: Object.fromEntries(
                  new URLSearchParams(`?${queryString}`)
                ),
              }
            : null),
          ...(ref ? { ref } : null),
          ...(aff ? { aff } : null),
          ...(fbclid ? { fbclid } : null),
          ...(gclid ? { gclid } : null),
        });
      });
  }

  ngAfterViewInit(): void {
    this.el.nativeElement.classList.add('!min-h-[200svh]');
    const isShowed = JSON.parse(
      this.cookieService.get('isCookieShowed') || 'false'
    );
    timer(3000)
      .pipe(take(1))
      .subscribe(() => {
        this.showCookieMsgComp.set(!isShowed);
        this.cookieService.set('isCookieShowed', 'true', undefined, '/');
      });
  }

  checkQueryParams(queryParams) {
    let isReload = false;
    const date = new Date();
    date.setMilliseconds(
      date.getMilliseconds() + environment.refAffStorageTime
    );
    if (REF in queryParams) {
      this.cookieService.set(REF, queryParams[REF], {
        expires: date,
        path: '/',
      });
      isReload = true;
    }
    if (AFF in queryParams) {
      this.cookieService.set(AFF, queryParams[AFF], {
        expires: date,
        path: '/',
      });
      isReload = true;
    }
    if (FACEBOOK in queryParams) {
      sessionStorage.setItem(FACEBOOK, queryParams[FACEBOOK]);
    }
    if (GOOGLE in queryParams) {
      sessionStorage.setItem(GOOGLE, queryParams[GOOGLE]);
    }
    if ('auth_token' in queryParams) {
      isReload = true;
      this.authService.accessToken = queryParams['auth_token'];
    }
    const gclid = sessionStorage.getItem(GOOGLE);
    const fbclid = sessionStorage.getItem(FACEBOOK);
    if (
      (!(FACEBOOK in queryParams) && fbclid) ||
      (!(GOOGLE in queryParams) && gclid)
    ) {
      const href = location.href;
      const queryString = href.split('?')[1];
      const query = Object.fromEntries(new URLSearchParams(queryString || ''));
      this.router.navigate([location.pathname], {
        relativeTo: this.activatedRoute,
        queryParams: {
          [GOOGLE]: gclid,
          [FACEBOOK]: fbclid,
          ...query,
        },
        replaceUrl: true,
        queryParamsHandling: 'merge',
        onSameUrlNavigation: 'ignore',
      });
    }
    if (isReload) {
      this.router.navigate([location.pathname], {
        relativeTo: this.activatedRoute,
        queryParams: {
          [REF]: null,
          [AFF]: null,
          auth_token: null,
        },
        queryParamsHandling: 'merge',
        onSameUrlNavigation: 'reload',
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubs.next(true);
    this.unsubs.complete();
    if (this.arSubs) {
      this.arSubs.unsubscribe();
    }
  }
}
