import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  OnDestroy,
  OnInit,
  inject,
  signal,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { StickyMobileMenuComponent } from '../modules/sticky-mobile-menu/sticky-mobile-menu.component';
import { MegaMenuComponent } from '../modules/mega-menu/mega-menu.component';
import { BreakpointObserver } from '../services/breakpoint.observer';
import { AsyncPipe } from '@angular/common';
import { CurrentCityService } from '../services/current-city.service';
import { filter, Subscription } from 'rxjs';
import { FooterComponent } from '../modules/footer/footer.component';
import { NavigationService } from '../services/navigation.service';
import { AuthService } from '../services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { AFF, FACEBOOK, GOOGLE, REF } from '../constants/storage.keys';
import { environment } from '../environments/environment';
import { GtmService } from '../services/gtm.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    StickyMobileMenuComponent,
    MegaMenuComponent,
    AsyncPipe,
    FooterComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [BreakpointObserver],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'biletzero.com';
  protected breakpointObserver = inject(BreakpointObserver);
  public isSmallScreen$ = this.breakpointObserver.isSmallScreen$;
  protected currentCityService = inject(CurrentCityService);
  protected navigationService = inject(NavigationService);
  protected activatedRoute = inject(ActivatedRoute);
  protected authService = inject(AuthService);
  protected cookieService = inject(CookieService);
  protected gtmService = inject(GtmService);
  protected router = inject(Router);
  public data = signal<any>(null);
  protected arSubs: Subscription;
  ngOnInit(): void {
    this.currentCityService.findCurrentCity();
    this.checkQueryParams(
      Object.fromEntries(new URLSearchParams(location.search))
    );
    this.arSubs = this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.checkQueryParams(queryParams);
    });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const queryString = event.url.split('?')[1] || '';
        const ref = this.cookieService.get(REF);
        const aff = this.cookieService.get(AFF);
        const fbclid = sessionStorage.getItem(FACEBOOK);
        const gclid = sessionStorage.getItem(GOOGLE);
        this.gtmService.push({
          event: 'vPageView',
          pagePath: event.url.split('?')[0],
          ...(queryString
            ? {
                queryParams: Object.fromEntries(
                  new URLSearchParams(`?${queryString}`)
                ),
              }
            : null),
          ...(ref ? { ref } : null),
          ...(aff ? { aff } : null),
          ...(fbclid ? { fbclid } : null),
          ...(gclid ? { gclid } : null),
        });
      });
  }

  checkQueryParams(queryParams) {
    let isReload = false;
    const date = new Date();
    date.setMilliseconds(
      date.getMilliseconds() + environment.refAffStorageTime
    );
    if (REF in queryParams) {
      this.cookieService.set(REF, queryParams[REF], {
        expires: date,
        path: '/',
      });
      isReload = true;
    }
    if (AFF in queryParams) {
      this.cookieService.set(AFF, queryParams[AFF], {
        expires: date,
        path: '/',
      });
      isReload = true;
    }
    if (FACEBOOK in queryParams) {
      sessionStorage.setItem(FACEBOOK, queryParams[FACEBOOK]);
    }
    if (GOOGLE in queryParams) {
      sessionStorage.setItem(GOOGLE, queryParams[GOOGLE]);
    }
    if ('auth_token' in queryParams) {
      isReload = true;
      this.authService.accessToken = queryParams['auth_token'];
    }
    const gclid = sessionStorage.getItem(GOOGLE);
    const fbclid = sessionStorage.getItem(FACEBOOK);
    if (
      (!(FACEBOOK in queryParams) && fbclid) ||
      (!(GOOGLE in queryParams) && gclid)
    ) {
      this.router.navigate([location.pathname], {
        relativeTo: this.activatedRoute,
        queryParams: {
          [GOOGLE]: gclid,
          [FACEBOOK]: fbclid,
        },
        queryParamsHandling: 'merge',
        onSameUrlNavigation: 'ignore',
      });
    }
    if (isReload) {
      this.router.navigate([location.pathname], {
        relativeTo: this.activatedRoute,
        queryParams: {
          [REF]: null,
          [AFF]: null,
          auth_token: null,
        },
        queryParamsHandling: 'merge',
        onSameUrlNavigation: 'reload',
      });
    }
  }

  ngOnDestroy(): void {
    if (this.arSubs) {
      this.arSubs.unsubscribe();
    }
  }

  onRouteChange(event: any) {
    this.data.set(event.activatedRoute?.snapshot?.data);
  }
}
