import { Component, inject, Input, OnInit, signal } from '@angular/core';
import {
  slideInLeft,
  slideInTop,
  slideOutLeft,
  slideOutTop,
} from '../../animations/slide';
import {
  AsyncPipe,
  DatePipe,
  NgClass,
  NgIf,
  NgTemplateOutlet,
} from '@angular/common';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { BreakpointObserver } from '../../services/breakpoint.observer';
import { MenuModel } from '../../interfaces/menu.interface';
import { TypeFilterPipe } from './type-filter.pipe';
import { SiteDataService } from '../../services/api/site-data.service';
import { filter, map, switchMap } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import {
  LocalizeRouterModule,
  LocalizeRouterService,
} from '@gilsdav/ngx-translate-router';
import { CurrentCityService } from '../../services/current-city.service';
import { ModalService } from '../../services/modal.service';
import { CitySelectorComponent } from '../city-selector/city-selector.component';
import { CategoryService } from '../../services/api/category.service';
import { AddCdnPipe } from '../../pipes/add-cdn.pipe';
import { SlugifyPipe } from '../../pipes/slugify.pipe';
import { AutoSuggestComponent } from '../auto-suggest/auto-suggest.component';
import { environment } from '../../environments/environment';
import { StringHelper } from '../../helpers/string.helper';

@Component({
  selector: 'mega-menu',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    AsyncPipe,
    TypeFilterPipe,
    NgTemplateOutlet,
    DatePipe,
    NgClass,
    TranslateModule,
    LocalizeRouterModule,
    RouterLinkActive,
    AddCdnPipe,
    SlugifyPipe,
    AutoSuggestComponent,
  ],
  animations: [slideInLeft, slideOutLeft, slideInTop, slideOutTop],
  providers: [BreakpointObserver],
  templateUrl: './mega-menu.component.html',
  styleUrl: './mega-menu.component.scss',
})
export class MegaMenuComponent implements OnInit {
  protected router = inject(Router);
  protected localizeRouterService = inject(LocalizeRouterService);
  protected siteDataService = inject(SiteDataService);
  protected currentCityService = inject(CurrentCityService);
  protected categoryService = inject(CategoryService);
  protected modalService = inject(ModalService);
  protected activatedRoute = inject(ActivatedRoute);
  public activeCity = this.currentCityService.city;
  public isExplorePage = signal(false);
  public isCitySelectionDisabled = environment.multiCityDisabled;

  public queryParams$ = this.activatedRoute.queryParams;

  @Input() hideCategories = false;

  public categories$ = this.categoryService.getCategories().pipe(
    switchMap((data) =>
      this.currentCityService.selectedCity$.pipe(
        map((selectedCity) => ({ data, selectedCity }))
      )
    ),
    map((result) => {
      return result.data
        .filter((item) => item.showInMenu)
        .map((item) => {
          let menuItem = { ...item };
          const subCategories = menuItem.subCategories.map((cat) => {
            return {
              ...cat,
              link: this.localizeRouterService.translateRoute([
                '/events',
                StringHelper.slugify(result.selectedCity),
                menuItem.slug || StringHelper.slugify(menuItem.name),
              ]),
              queryParams: { subCategories: cat.name },
            };
          });
          const popularEvents = (menuItem.popularEvents || []).map((event) => {
            return {
              ...event,
              link: this.localizeRouterService.translateRoute([
                '/events',
                StringHelper.slugify(result.selectedCity),
                menuItem.slug || StringHelper.slugify(menuItem.name),
                event.slug || StringHelper.slugify(event.name),
              ]),
            };
          });
          const banners = menuItem.banners || [];
          delete menuItem.subCategories;
          delete menuItem.popularEvents;
          delete menuItem.banners;
          return {
            ...menuItem,
            children: [
              subCategories.length
                ? {
                    type: 'basic-group',
                    children: subCategories,
                  }
                : null,
              popularEvents.length
                ? {
                    label: 'popularEvents',
                    type: 'group',
                    children: popularEvents.map((data: any) => ({
                      type: 'card',
                      data,
                    })),
                  }
                : null,
              banners.length
                ? {
                    type: 'group',
                    children: banners.map((data: any) => ({
                      type: 'banner',
                      data,
                    })),
                  }
                : null,
            ].filter(Boolean),
          };
        });
    })
  );

  public exploreMenu: Array<MenuModel> = [
    { type: 'title', link: 'all' },
    { type: 'title', link: 'events' },
    { type: 'title', link: 'places' },
    { type: 'title', link: 'artists' },
    { type: 'title', link: 'organizators' },
  ];

  protected breakpointObserver = inject(BreakpointObserver);

  public isSmallScreen$ = this.breakpointObserver.isSmallScreen$;

  public activeSubMenuIndex = signal(-1);

  public showMenu = signal(false);

  public isSearchActive = signal(false);

  constructor() {}

  ngOnInit(): void {
    const host = location.host;
    let url = this.router.url.replace(host, '');
    const exploreKey = this.localizeRouterService.translateRoute('explore');
    this.router.events
      .pipe(filter((events) => events instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const exploreKey = this.localizeRouterService.translateRoute(
          'explore'
        ) as string;
        this.isExplorePage.set(event.url.includes(exploreKey));
      });
  }

  public toggleMenu() {
    this.showMenu.set(!this.showMenu());
    if (!this.showMenu()) {
      this.activeSubMenuIndex.set(-1);
    }
  }

  public toggleSubMenuIndex(index: number) {
    const currentIndex = this.activeSubMenuIndex();
    this.activeSubMenuIndex.set(currentIndex === index ? -1 : index);
  }

  public setSubMenuIndex(index: number, isSmallScreen: any) {
    if (isSmallScreen) {
      return;
    }
    this.activeSubMenuIndex.set(index);
  }

  public openModal() {
    this.modalService.open(CitySelectorComponent, {
      type: 'side',
      classList: ['px-4'],
      animation: { duration: '300ms' },
    });
  }
}
