<div class="relative text-lg text-center mb-7 p-4 border-b border-b-black/60">
  <span class="text-xl font-bold font-syne">{{
    "order.qrCode" | translate
  }}</span>
  <button (click)="close()">
    <span class="liga-cross absolute top-3 right-4 text-2xl"></span>
  </button>
</div>
<div class="flex flex-col pb-16 px-10">
  <img [src]="initialData.image" class="w-full aspect-square" />
</div>
