import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CurrentCityService } from '../../services/current-city.service';
import { filter, Subject, take, takeUntil } from 'rxjs';
import { ModalComponent } from '../modal/modal.component';
import { Router } from '@angular/router';
import { StringHelper } from '../../helpers/string.helper';
import { GtmService } from '../../services/gtm.service';

@Component({
  selector: 'city-selector',
  standalone: true,
  imports: [TranslateModule, ReactiveFormsModule],
  templateUrl: './city-selector.component.html',
  styleUrl: './city-selector.component.scss',
})
export class CitySelectorComponent implements OnInit, OnDestroy {
  protected cityService = inject(CurrentCityService);
  protected translateService = inject(TranslateService);
  protected gtmService = inject(GtmService);
  searchInput = new FormControl('');
  nearByCities = new FormControl(this.cityService.nearByCities());
  protected unsubs = new Subject<void>();
  public modalComponent?: ModalComponent<CitySelectorComponent>;
  public filteredCities = signal<Array<any>>([]);
  protected router = inject(Router);
  previousCity = '';
  previousNearBy = false;
  selectedCity = '';

  ngOnInit(): void {
    this.selectedCity = this.cityService.city();
    this.previousCity = this.cityService.city();
    this.previousNearBy = this.cityService.nearByCities();
    this.cityService
      .getCities()
      .pipe(
        filter((data: any) => !!data?.length),
        take(1)
      )
      .subscribe((data) => {
        const customOrder = ['İstanbul', 'Ankara', 'İzmir'];
        this.filteredCities.set([
          ...data.sort((a, b) => {
            const indexA = customOrder.indexOf(a.name);
            const indexB = customOrder.indexOf(b.name);

            if (indexA !== -1 && indexB !== -1) {
              // If both cities are in the custom order array, sort by their order in the array
              return indexA - indexB;
            } else if (indexA !== -1) {
              // If only 'a' is in the custom order array, it should come first
              return -1;
            } else if (indexB !== -1) {
              // If only 'b' is in the custom order array, it should come first
              return 1;
            } else {
              // If neither city is in the custom order array, maintain their original order
              return 0;
            }
          }),
        ]);
      });

    this.searchInput.valueChanges
      .pipe(takeUntil(this.unsubs))
      .subscribe((value: string | null) => {
        const lang = `${
          this.translateService.currentLang
        }-${this.translateService.currentLang.toUpperCase()}`;
        value = (value || '').trim().toLocaleLowerCase(lang);
        this.filteredCities.set(
          this.cityService.$cities
            .getValue()
            .filter((item: any) =>
              (item.name as string).toLocaleLowerCase(lang).startsWith(value)
            )
        );
      });
  }
  ngOnDestroy(): void {
    if (
      this.previousCity !== this.selectedCity ||
      this.previousNearBy !== this.cityService.nearByCities()
    ) {
      const lang = `${
        this.translateService.currentLang
      }-${this.translateService.currentLang.toUpperCase()}`;
      let pathName = location.pathname;
      this.cityService.nearByCities.set(this.nearByCities.value);
      this.cityService.city.set(this.selectedCity);
      if (
        decodeURIComponent(pathName).includes(
          StringHelper.slugify(this.previousCity.toLocaleLowerCase(lang))
        ) ||
        decodeURIComponent(pathName) === `/${this.translateService.currentLang}`
      ) {
        pathName = decodeURIComponent(pathName).replace(
          StringHelper.slugify(this.previousCity.toLocaleLowerCase(lang)),
          StringHelper.slugify(this.selectedCity.toLocaleLowerCase(lang))
        );
        this.gtmService.push({
          event: 'cityChange',
          fromCity: StringHelper.slugify(
            this.previousCity.toLocaleLowerCase(lang)
          ),
          toCity: StringHelper.slugify(
            this.selectedCity.toLocaleLowerCase(lang)
          ),
        });
        this.router.navigateByUrl(pathName);
      }
    }
    this.unsubs.next();
    this.unsubs.complete();
  }

  onSelect(item: any) {
    if (item.name === this.cityService.city()) {
      return;
    }
    this.selectedCity = item.name;
    this.modalComponent?.close();
  }
  close() {
    this.modalComponent?.close();
  }
}
