import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  EnvironmentInjector,
  inject,
  Injectable,
  signal,
  TemplateRef,
  Type,
} from '@angular/core';
import { ModalComponent } from '../modules/modal/modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  protected appRef = inject(ApplicationRef);
  protected envInjector = inject(EnvironmentInjector);
  protected modals = signal([]);
  public backdrop!: HTMLElement;
  protected modalRef: ComponentRef<any> | undefined;
  public open(
    temp: TemplateRef<any> | Type<any>,
    options?: {
      type: 'modal' | 'side' | 'side-right' | 'sheet';
      classList?: Array<string>;
      animation?: { duration: string } | null;
      data?: any;
    }
  ) {
    const modal = document.createElement('modal');
    this.modalRef = createComponent(ModalComponent, {
      environmentInjector: this.envInjector,
      hostElement: modal,
    });
    if (temp instanceof Type) {
      this.modalRef.instance.comp = temp;
    }
    this.modalRef.instance.data = options?.data;
    this.modalRef.instance.ref = this.modalRef;
    this.modalRef.instance.type = options?.type || 'modal';
    this.modalRef.instance.classList = options?.classList || [];
    this.modalRef.instance.animation = options?.animation || null;
    this.appRef.attachView(this.modalRef.hostView);
    if (!this.backdrop) {
      this.backdrop = document.createElement('div');
      this.backdrop.classList.add(
        'hidden',
        'modal-backdrop',
        'fixed',
        'inset-0',
        'bg-black',
        'bg-opacity-20',
        'z-[9999]'
      );

      document.body.appendChild(this.backdrop);
    }
    this.modalRef.instance.backdrop = this.backdrop;
    this.backdrop.classList.remove('hidden');
    this.backdrop.onclick = () => {
      this.modalRef?.instance.close();
    };
    this.modalRef?.onDestroy(() => {
      this.modalRef?.instance.afterCloseCallback();
    });
    document.body.appendChild(modal);
    document.body.classList.add('overflow-hidden');

    return this.modalRef.instance as ModalComponent<typeof temp>;
  }
}
