import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { environment } from '../environments/environment';
import { AuthHelper } from '../helpers/auth.helper';
import {
  BehaviorSubject,
  catchError,
  defer,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  shareReplay,
  tap,
  throwError,
} from 'rxjs';
import { UserService } from './user.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { GtmService } from './gtm.service';
import * as hash from 'blueimp-md5';
import { StringHelper } from '../helpers/string.helper';
import { Auth, GoogleAuthProvider, signInWithPopup } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  protected http = inject(HttpClient);
  protected userService = inject(UserService);
  protected toastrService = inject(ToastrService);
  protected translate = inject(TranslateService);
  protected auth = inject(Auth);
  protected token = signal('');
  protected $accessToken = new BehaviorSubject(
    localStorage.getItem('accessToken') ?? ''
  );

  protected gtmService = inject(GtmService);

  protected fireBaseLoginResponse = new BehaviorSubject(null);
  public fireBaseLoginResponse$ = this.fireBaseLoginResponse
    .asObservable()
    .pipe(filter(Boolean));

  public accessToken$ = this.$accessToken.asObservable().pipe(
    distinctUntilChanged(),
    map((token) => token?.trim())
  );

  authenticated = false;

  set accessToken(token: string) {
    this.$accessToken.next(token);
    this.authenticated = !!token;
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    this.authenticated = !!localStorage.getItem('accessToken');

    return localStorage.getItem('accessToken') ?? '';
  }

  set refreshToken(token: string) {
    localStorage.setItem('refreshToken', token);
  }

  get refreshToken(): string {
    return localStorage.getItem('refreshToken') ?? '';
  }

  signIn(credentials: any, type: 'email' | 'phone') {
    credentials = { ...credentials };
    let req = null;
    if (type === 'phone') {
      delete credentials.email;
      req = this.http.post(
        `${environment.apiHost}Authentication/signin-with-phone`,
        credentials
      );
    } else {
      delete credentials.phoneNumber;
      req = this.http.post(
        `${environment.apiHost}Authentication/signin`,
        credentials
      );
    }
    return req.pipe(
      map((response: any) => response.data),
      tap((data: any) => {
        const user = { ...data.user };

        // Set the authenticated flag to true
        this.userService.user = user;
        this.authenticated = true;
        this.accessToken = data.token;
        this.refreshToken = data.refreshToken;
        this.gtmService.push({
          event: 'userLoggedIn',
          user: {
            email: hash(user.email),
            phoneNumber: hash(user.phoneNumber),
          },
        });
        this.toastrService.success(this.translate.instant('successMsg.login'));
      }),
      map((data: any) => data?.user),
      catchError((err) => {
        if (err.error.errors) {
          this.toastrService.error(Object.values(err.error.errors).join(' '));
        } else if (err.error.error) {
          this.toastrService.error(err.error.error.messages.join(' '));
        } else {
          this.toastrService.error(this.translate.instant('errorMsg.generic'));
        }
        return throwError(err);
      }),
      shareReplay(1)
    );
  }
  check() {
    return this.accessToken$.pipe(
      map((token) => {
        return token ? !AuthHelper.isTokenExpired(token) : false;
      })
    );
  }
  register(credentials: any, isForeignNumber) {
    return this.http
      .post(`${environment.apiHost}Authentication/register`, credentials)
      .pipe(
        map((response: any) => response.data),
        tap((data) => {
          const accessToken = data.token;
          const refreshToken = data.refreshToken;
          delete data.token;
          delete data.refreshToken;
          const user = { ...data, phoneNumber: data.phone };

          this.gtmService.push({
            event: 'userRegister',
            user: {
              email: hash(user.email),
              phoneNumber: hash(user.phone),
            },
          });
          // Set the authenticated flag to true
          this.userService.user = user;
          this.authenticated = true;
          this.accessToken = accessToken;
          this.refreshToken = refreshToken;
          this.toastrService.success(
            this.translate.instant(
              isForeignNumber
                ? 'successMsg.registerForeign'
                : 'successMsg.register'
            )
          );
        }),
        catchError((err) => {
          if (err.error.errors) {
            this.toastrService.error(Object.values(err.error.errors).join(' '));
          } else if (err.error.error) {
            this.toastrService.error(err.error.error.messages.join(' '));
          } else {
            this.toastrService.error(
              this.translate.instant('errorMsg.generic')
            );
          }
          return throwError(err);
        }),
        shareReplay(1)
      );
  }
  sendVerificationCodeRegister(): Observable<string> {
    return this.http
      .put(`${environment.apiHost}Authentication/send-code`, null)
      .pipe(
        map((response: any) => response.data),
        catchError((err) => {
          if (err.error.errors) {
            this.toastrService.error(Object.values(err.error.errors).join(' '));
          } else if (err.error.error) {
            this.toastrService.error(err.error.error.messages.join(' '));
          } else {
            this.toastrService.error(
              this.translate.instant('errorMsg.generic')
            );
          }
          return throwError(err);
        })
      );
  }

  changePhoneNumberForVerification(phone) {
    return this.http
      .put(
        `${environment.apiHost}Authentication/phone-for-resend-verify-code`,
        { phoneNumber: phone }
      )
      .pipe(
        map((response: any) => response.data),
        map((data) => {
          const accessToken = data.token;
          const refreshToken = data.refreshToken;
          delete data.token;
          delete data.refreshToken;
          const user = { ...data, phoneNumber: data.phone };

          // Set the authenticated flag to true
          this.userService.user = user;
          this.authenticated = true;
          this.accessToken = accessToken;
          this.refreshToken = refreshToken;
          return user;
        }),
        catchError((err) => {
          if (err.error.errors) {
            this.toastrService.error(Object.values(err.error.errors).join(' '));
          } else if (err.error.error) {
            this.toastrService.error(err.error.error.messages.join(' '));
          } else {
            this.toastrService.error(
              this.translate.instant('errorMsg.generic')
            );
          }
          return throwError(err);
        })
      );
  }

  signOut(showToastr = true) {
    // Set the authenticated flag to false
    this.authenticated = false;
    if (this.userService.user) {
      this.gtmService.push({
        event: 'userLoggedOut',
        user: {
          email: hash(this.userService.user.email),
          phoneNumber: hash(this.userService.user.phoneNumber),
        },
      });
    }
    this.accessToken = '';
    this.refreshToken = '';
    this.userService.user = null;
    this.userService.guestId = StringHelper.generateGUID();
    if (showToastr) {
      this.toastrService.success(this.translate.instant('successMsg.logout'));
    }
  }

  verifyPhoneRegister(otp, isMail = false) {
    return this.http
      .put(`${environment.apiHost}Authentication/verify-code/${otp}`, null)
      .pipe(
        map((response: any) => response.data),
        tap((data) => {
          const accessToken = data.token;
          const refreshToken = data.refreshToken;
          delete data.token;
          delete data.refreshToken;
          const user = { ...data };

          // Set the authenticated flag to true
          this.userService.user = user;
          this.authenticated = true;
          this.accessToken = accessToken;
          this.refreshToken = refreshToken;
          this.toastrService.success(
            this.translate.instant(
              isMail ? 'successMsg.emailVerified' : 'successMsg.phoneVerified'
            )
          );
        }),
        catchError((err) => {
          if (err.error.errors) {
            this.toastrService.error(Object.values(err.error.errors).join(' '));
          } else if (err.error.error) {
            this.toastrService.error(err.error.error.messages.join(' '));
          } else {
            this.toastrService.error(
              this.translate.instant('errorMsg.generic')
            );
          }
          return throwError(err);
        })
      );
  }

  forgotPasword(phone) {
    return this.http
      .post(`${environment.apiHost}User/forgot-password`, { phone })
      .pipe(
        map((response: any) => response.data),
        catchError((err) => {
          if (err.error.errors) {
            this.toastrService.error(Object.values(err.error.errors).join(' '));
          } else if (err.error.error) {
            this.toastrService.error(err.error.error.messages.join(' '));
          } else {
            this.toastrService.error(
              this.translate.instant('errorMsg.generic')
            );
          }
          return throwError(err);
        })
      );
  }

  validateOtp(phone, otp) {
    return this.http
      .post(`${environment.apiHost}User/validate-otp`, { phone, otp })
      .pipe(
        map((response: any) => response.data),
        catchError((err) => {
          if (err.error.errors) {
            this.toastrService.error(Object.values(err.error.errors).join(' '));
          } else if (err.error.error) {
            this.toastrService.error(err.error.error.messages.join(' '));
          } else {
            this.toastrService.error(
              this.translate.instant('errorMsg.generic')
            );
          }
          return throwError(err);
        })
      );
  }

  changePassword(accessToken, password) {
    return this.http
      .post(`${environment.apiHost}User/change-password`, {
        accessToken,
        password,
      })
      .pipe(
        map((response: any) => response.data),
        tap(() =>
          this.toastrService.success(
            this.translate.instant('successMsg.changePassword')
          )
        ),
        catchError((err) => {
          if (err.error.errors) {
            this.toastrService.error(Object.values(err.error.errors).join(' '));
          } else if (err.error.error) {
            this.toastrService.error(err.error.error.messages.join(' '));
          } else {
            this.toastrService.error(
              this.translate.instant('errorMsg.generic')
            );
          }
          return throwError(err);
        })
      );
  }

  initFirebaseTokenChangeListener() {
    this.auth.onIdTokenChanged(async (data) => {
      if (data && !this.accessToken && environment.googleLoginEnabled) {
        this.http
          .post(`${environment.apiHost}Authentication/firebase-login`, {
            firebaseToken: await data.getIdToken(),
          })
          .subscribe((res: any) => {
            const data = res.data;
            const user = { ...data.user };

            // Set the authenticated flag to true
            this.userService.user = user;
            this.authenticated = true;
            this.accessToken = data.token;
            this.refreshToken = data.refreshToken;
            this.fireBaseLoginResponse.next(user);
            this.gtmService.push({
              event: 'userLoggedIn',
              user: {
                email: hash(user.email),
                phoneNumber: hash(user.phoneNumber),
              },
            });
            this.toastrService.success(
              this.translate.instant('successMsg.login')
            );
          });
      }
    });
  }

  signInWithGooglePopup() {
    const provider = new GoogleAuthProvider();
    defer(() => signInWithPopup(this.auth, provider)).subscribe();
    return this.fireBaseLoginResponse$;
  }
}
