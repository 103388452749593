import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GtmService {
  protected document = inject(DOCUMENT);
  protected win: Window & { dataLayer?: Array<any> } =
    this.document.defaultView;

  constructor() {}

  push(data) {
    if (!this.win.dataLayer) {
      this.win.dataLayer = [];
    }
    this.win.dataLayer?.push(data);
  }
}
