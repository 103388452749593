@if (!modalComponent){
<div
  class="sticky z-1 top-14 left-0 -mx-4 bg-headerBg text-headerFg py-4"
  [class.md:bg-[#1e1e1e]]="status() === 'detail'"
  [class.md:text-white]="status() === 'detail'"
  [class.md:bg-transparent]="status() !== 'detail'"
  [class.md:text-black]="status() !== 'detail'"
>
  @if(!status()){
  <button
    backButton
    class="md:hidden absolute flex items-center justify-center top-6 left-4 text-secondaryBtnFg"
  >
    <span class="liga-arrow-left"></span></button
  >} @else{
  <button
    (click)="backToForm()"
    class="md:hidden absolute flex items-center justify-center top-6 left-4 text-secondaryBtnFg"
  >
    <span class="liga-arrow-left"></span></button
  >}
  <h1 class="font-syne font-bold text-lg capitalize w-full text-center">
    {{
      (!status() ? "searchGuestOrder" : "profilePage.orderDetail") | translate
    }}
  </h1>
  @if(status() === 'detail'){
  <button
    class="absolute text-xl right-4 top-6"
    [class.liga-drawer-download]="!isGenerationContinue()"
    (click)="downloadOrder(ordersEl, orderDetail(), null)"
  >
    @if(isGenerationContinue()){
    <svg
      aria-hidden="true"
      class="w-5 h-5 text-skeletonFg animate-spin fill-blue-600"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>
    }
  </button>
  }
</div>
}@else {
<div class="sticky z-1 top-14 left-0 bg-headerBg text-headerFg w-full pb-4">
  <button
    class="absolute flex items-center justify-center top-2 left-2 text-secondaryBtnFg"
    (click)="close()"
  >
    <span class="liga-cross text-secondaryBtnFg"></span>
  </button>

  <h1 class="font-syne font-bold text-lg capitalize w-full text-center">
    {{
      (!status() ? "searchGuestOrder" : "profilePage.orderDetail") | translate
    }}
  </h1>
  @if(status() === 'detail'){
  <button
    class="absolute text-xl right-4 top-6"
    [class.liga-drawer-download]="!isGenerationContinue()"
    (click)="downloadOrder(ordersEl, orderDetail(), null)"
  >
    @if(isGenerationContinue()){
    <svg
      aria-hidden="true"
      class="w-5 h-5 text-skeletonFg animate-spin fill-blue-600"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>
    }
  </button>
  }
</div>
} @if(!status()){
<div class="flex flex-col mt-10 gap-4 md:max-w-sm md:mx-auto">
  <div class="font-semibold text-justify">
    {{ "guestOrderTitle" | translate }}
  </div>
  <div class="font-normal text-sm text-justify">
    {{ "guestOrderDesc" | translate }}
  </div>
  <form
    #formEl
    [formGroup]="form"
    (ngSubmit)="searchPnr(formEl)"
    class="flex flex-col gap-6 mt-6"
  >
    <div class="flex flex-col gap-2">
      <label for="email" class="font-semibold text-sm capitalize">{{
        "email" | translate
      }}</label>
      <input
        id="email"
        class="border border-tertiaryBorder h-12 w-full rounded-none outline-none px-2 [&.ng-invalid.ng-touched]:border-red-500"
        name="email"
        formControlName="identity"
        type="email"
        [validation]
        [showError]="showValidationErrors()"
      />
    </div>
    <div class="flex flex-col gap-2">
      <label for="pnr" class="font-semibold text-sm capitalize">{{
        "pnr" | translate
      }}</label>
      <input
        id="pnr"
        class="border border-tertiaryBorder h-12 w-full rounded-none outline-none px-2 [&.ng-invalid.ng-touched]:border-red-500"
        name="pnr"
        formControlName="pnr"
        type="text"
        [validation]
        [showError]="showValidationErrors()"
      />
    </div>
    <button
      type="submit"
      class="py-4 w-full bg-secondaryBtnBg text-secondaryBtnFg uppercase tracking-[0.1px] font-bold"
    >
      {{ "findOrder" | translate }}
    </button>
  </form>
</div>
} @else if (status() === 'detail') {
<div
  #ordersEl
  class="flex flex-wrap justify-around gap-5 px-4 pt-3 pb-14 bg-tertiaryBg"
>
  @if(orderDetail(); as orderDetail){ @for(entry of orderDetail.orderEntries;
  track entry.id; let i = $index){

  <div
    #orderEl
    class="order-item flex flex-col bg-primaryBg py-3 px-6 w-full gap-5"
  >
    <div class="grid grid-cols-1 gap-3">
      <div class="col-span-full realtive -mt-3 -mx-6">
        <img
          [src]="entry.image"
          [alt]="orderDetail.eventName"
          [image]
          class="w-full aspect-video object-cover"
        />
        @if(entry.ticketStatusEnum !== 0){
        <div
          class="bg-red-500 text-nonaryFg w-full text-center bottom-0 left-0 py-2"
        >
          {{
            "order." + entry.ticketStatus
              | translate
                : { dateTime: entry.usedTime | date : "d MMMM yyyy - HH:mm" }
          }}
        </div>
        }
      </div>
      <div class="grid grid-cols-2 grid-flow-dense gap-2">
        <div class="flex col-span-full">
          <div
            class="relative font-syne text-xl uppercase font-bold w-full pr-6"
          >
            {{ orderDetail.eventName }}
            <button
              class="absolute right-0 top-0"
              (click)="downloadOrder(orderEl, orderDetail, i)"
              [class.liga-drawer-download]="!isGenerationContinue()"
            ></button>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="text-primaryFg/70 text-xs capitalize">
            {{ "order.fullName" | translate }}
          </div>
          <div class="text-sm font-bold">{{ orderDetail.buyer }}</div>
        </div>
        <div class="flex flex-col">
          <div class="text-primaryFg/70 text-xs capitalize">
            {{ "order.category" | translate }}
          </div>
          <div class="text-sm font-bold">
            {{ entry.priceName || entry.productName }}
          </div>
        </div>
        @if(entry.availableStartDate){
        <div class="flex flex-col">
          <div class="text-primaryFg/70 text-xs capitalize">
            {{ "order.validDate" | translate }}
          </div>
          <div class="text-sm font-bold">
            {{ entry.availableStartDate | date : "d MMMM, EEEE" }}
            @if(entry.isAvailableDateDiffer){ -
            {{ entry.availableEndDate | date : "d MMMM, EEEE" }} }
          </div>
        </div>
        }
        <div class="flex flex-col">
          <div class="text-primaryFg/70 text-xs capitalize">
            {{ "order.time" | translate }}
          </div>
          <div class="text-sm font-bold">
            {{ orderDetail.event?.date | date : "HH:mm" }}
          </div>
        </div>
        <div class="flex flex-col">
          <div class="text-primaryFg/70 text-xs capitalize">
            {{ "order.price" | translate }}
          </div>
          <div class="text-sm font-bold">
            {{
              entry.basePrice
                ? (entry.basePrice | currency : "TRY" : "symbol" : "1.0-2")
                : ("order.free" | translate)
            }}
          </div>
        </div>
        @if(entry.seatBlock){
        <div class="flex flex-col">
          <div class="text-primaryFg/70 text-xs capitalize">
            {{ "order.seat" | translate }}
          </div>
          <div class="text-sm font-bold">
            {{ entry.seatBlock }} / {{ entry.seatOrder }}
          </div>
        </div>
        }

        <div class="flex flex-col col-span-full">
          <div class="text-primaryFg/70 text-xs capitalize">
            {{ "order.location" | translate }}
          </div>
          <div class="text-sm font-bold">{{ entry.place }}</div>
        </div>
      </div>
    </div>
    @if (entry.ticketStatusEnum === 0){
    <div
      class="item-seperator h-0.5 rounded-xl -mx-3 relative before:content-[''] before:absolute before:block before:top-[2px] before:w-5 before:h-10 before:rounded-e-full before:-left-3 before:-translate-y-1/2 before:bg-primaryBg after:content-[''] after:absolute after:block after:top-[2px] after:w-5 after:h-10 after:rounded-s-full after:-right-3 after:-translate-y-1/2 after:bg-primaryBg"
    ></div>
    <div class="flex flex-col gap-3">
      <div class="flex justify-between">
        @if(entry.qrCode$ | async; as qrImage){
        <div class="flex flex-col">
          <div class="text-sm text-center mb-2">{{ entry.qrCode }}</div>
          <img
            [src]="qrImage"
            [alt]="orderDetail.eventName"
            class="w-full aspect-square object-cover"
            (click)="openZoom(qrImage)"
          />
        </div>
        }
        <img src="/assets/images/vertical.svg" class="h-full w-auto ml-auto" />
      </div>
      <div
        class="flex col-span-full gap-1 justify-center items-center text-base"
      >
        <div class="capitalize">{{ "order.pnr" | translate }}:</div>
        <div>{{ orderDetail.pnr }}</div>
      </div>
    </div>
    }
  </div>
  } }
</div>
}
