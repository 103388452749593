import {
  AfterViewInit,
  Component,
  effect,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  Self,
  signal,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalComponent } from '../../modules/modal/modal.component';
import { ModalService } from '../../services/modal.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { ValidationDirective } from '../../directives/validation.directive';
import { OrdersService } from '../../services/orders.service';
import { ToastrService } from 'ngx-toastr';
import { AddCdnPipe } from '../../pipes/add-cdn.pipe';
import { AsyncPipe, CurrencyPipe, DatePipe } from '@angular/common';
import { BackButtonDirective } from '../../directives/back-button.directive';
import { QrZoomComponent } from '../../modules/qr-zoom/qr-zoom.component';
import QRCodeStyling from 'qr-code-styling';
import { defer, filter, map, Observable, take, tap } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { ImageDirective } from '../../directives/image.directive';
import jsPDF from 'jspdf';
import { LoadingService } from '../../services/loading.service';
import html2canvas from 'html2canvas';

@Component({
  selector: 'guest-order',
  standalone: true,
  imports: [
    TranslateModule,
    LocalizeRouterModule,
    ValidationDirective,
    ReactiveFormsModule,
    AddCdnPipe,
    DatePipe,
    CurrencyPipe,
    BackButtonDirective,
    AsyncPipe,
    ImageDirective,
  ],
  templateUrl: './guest-order.component.html',
  styleUrl: './guest-order.component.scss',
})
export class GuestOrderComponent implements AfterViewInit, OnDestroy, OnInit {
  public activatedRoute = inject(ActivatedRoute);
  protected modalService = inject(ModalService);
  protected ordersService = inject(OrdersService);
  protected toastrService = inject(ToastrService);
  protected translate = inject(TranslateService);
  protected elRef = inject(ElementRef);
  protected loadingService = inject(LoadingService);
  public modalComponent?: ModalComponent<Self>;
  public initialData: any = {};
  public isGenerationContinue = signal(false);
  protected title = inject(Title);

  public orderDetail = signal(null);

  @ViewChild('formEl', { static: false }) formEl: ElementRef;
  @ViewChild('ordersEl', { static: false }) ordersEl: ElementRef;

  form = new FormGroup({
    identity: new FormControl('', [Validators.required, Validators.email]),
    pnr: new FormControl(null, [Validators.required]),
  });

  public status = signal<'' | 'detail'>('');

  public showValidationErrors = signal(false);

  constructor() {
    effect(() => {
      const status = this.status();
      if (status === 'detail') {
        document.body.style.backgroundColor = '#1e1e1e';
        this.elRef.nativeElement.style.backgroundColor = '#1e1e1e';
      } else {
        document.body.style.backgroundColor = '';
        this.elRef.nativeElement.style.backgroundColor = '';
      }
    });
  }

  ngOnInit(): void {
    this.title.setTitle(this.translate.instant('searchGuestOrder'));
  }

  ngAfterViewInit(): void {
    const guestInfo = this.ordersService.getGuestUserInfo();
    if (guestInfo) {
      this.form.setValue(guestInfo);
      this.ordersService.clearGuestUSerInfo();
      this.searchPnr(this.formEl?.nativeElement);
    }
    this.activatedRoute.queryParams
      .pipe(
        take(1),
        tap((queryParams) => {
          if (queryParams['pnr'] && queryParams['mail']) {
            this.form.setValue({
              identity: queryParams['mail'],
              pnr: queryParams['pnr'],
            });
            this.searchPnr(this.formEl?.nativeElement);
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    document.body.style.backgroundColor = '';
    this.elRef.nativeElement.style.backgroundColor = '';
  }

  backToForm() {
    this.status.set('');
  }

  searchPnr(formEl: HTMLFormElement) {
    if (this.form.invalid) {
      this.showValidationErrors.set(true);
      formEl.reportValidity();
      return;
    }
    this.ordersService.getGuestOrderDetail(this.form.value as any).subscribe(
      (orderDetail) => {
        this.orderDetail.set({
          ...orderDetail,
          orderEntries: orderDetail.orderEntries.map((entry: any) => {
            const availableStart = (entry.availableStartDate || '').split(
              'T'
            )?.[0];
            const availableEnd = (entry.availableEndDate || '').split('T')?.[0];
            const qrCode = new QRCodeStyling({
              width: 600,
              height: 600,
              margin: 0,
              data: entry.qrCode,
              dotsOptions: { type: 'classy', color: '#000000' },
              backgroundOptions: {
                color: '#ffffff00',
              },
              cornersSquareOptions: { color: '#000000' },
              cornersDotOptions: { color: '#000000' },
              imageOptions: {
                crossOrigin: 'anonymous',
                hideBackgroundDots: false,
                imageSize: 0.1,
                margin: 4,
              },
            });

            return {
              ...entry,
              isAvailableDateDiffer: availableStart !== availableEnd,
              qrCode$: defer(() =>
                qrCode
                  .getRawData()
                  .then((bData) => (bData ? this.blobToBase64(bData as Blob) : ''))
              ).pipe(filter(Boolean)) as Observable<string>,
            } as any;
          }),
        });
        this.status.set('detail');
        this.form.reset();
      },
      (err) => {
        if (err.error.errors) {
          this.toastrService.error(Object.values(err.error.errors).join(' '));
        } else if (err.error.error) {
          this.toastrService.error(err.error.error.messages.join(' '));
        } else {
          this.toastrService.error(
            this.translate.instant('errorMsg.pnrOrEmailMismatch')
          );
        }
      }
    );
  }

  blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string); // Split to remove the data URL part
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(blob);
    });
  }

  openZoom(image) {
    this.modalService.open(QrZoomComponent, {
      type: 'sheet',
      animation: { duration: '300ms' },
      data: { image },
    });
  }

  async downloadOrder(
    el: ElementRef | HTMLElement,
    orderDetail?,
    entryIndex?: number | null
  ) {
    if (this.isGenerationContinue()) {
      return;
    }
    this.loadingService.showLoading();
    this.isGenerationContinue.set(true);
    if (entryIndex !== null && el instanceof HTMLElement) {
      const doc = await this.singleTicketGenerator(el);
      if (doc) {
        doc.save(
          `${orderDetail.eventName.trim()}-${orderDetail.buyer.trim()}-${
            orderDetail.orderEntries[entryIndex].qrCode
          }.pdf`
        );
      }
      this.isGenerationContinue.set(false);
      this.loadingService.hideLoading();
      return;
    }
    el = (el instanceof ElementRef ? el.nativeElement : el) as HTMLElement;
    const els = Array.from(el.querySelectorAll('.order-item')) as HTMLElement[];
    const initEl = els.splice(0, 1)[0] as HTMLElement;
    const doc = await this.singleTicketGenerator(initEl);
    if (doc) {
      await els.reduce(async (promise, el) => {
        await promise; // Wait for the previous promise to resolve
        await this.singleTicketGenerator(el, doc);
      }, Promise.resolve());
      doc.save(
        `${orderDetail.eventName.trim()}-${orderDetail.buyer.trim()}.pdf`
      );
    }
    this.isGenerationContinue.set(false);
    this.loadingService.hideLoading();
  }

  async singleTicketGenerator(
    el: HTMLElement,
    doc?: jsPDF
  ): Promise<jsPDF | null> {
    const canvas = await html2canvas(el);
    const imageDataUri = canvas.toDataURL(`image/png`);
    const rect = el.getBoundingClientRect();
    const width = rect.width;
    const height = rect.height;
    if (!doc) {
      doc = new jsPDF({
        unit: 'px',
        format: [200, (200 / width) * height],
      });
    } else {
      doc = doc.addPage();
    }
    const docWidth = doc.internal.pageSize.getWidth();
    const docHeight = doc.internal.pageSize.getHeight();
    const widthRatio = width / docWidth;
    const heightRatio = height / docHeight;
    if (widthRatio >= heightRatio) {
      const imgH = (1 / widthRatio) * height;
      doc.addImage(
        imageDataUri,
        'PNG',
        0,
        (docHeight - imgH) / 2,
        docWidth,
        imgH
      );
    } else {
      const imgW = (1 / heightRatio) * width;
      doc.addImage(
        imageDataUri,
        'PNG',
        (docWidth - imgW) / 2,
        0,
        imgW,
        docHeight
      );
    }
    return doc;
  }

  async replaceImgWithBase64(el: HTMLElement) {
    const images = Array.from(el.querySelectorAll('img'));
    const assets = await Promise.all(
      images.map((item) =>
        item.src.startsWith('http')
          ? new Promise((resolve) => {
              let img = new Image();
              img.crossOrigin = 'Anonymous'; // This is needed to avoid cross-origin issues
              let isImageOnError = false;
              img.onload = function () {
                let canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;

                let ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);

                // Get the data-URL formatted image
                // Default format is PNG
                let dataURL = canvas.toDataURL('image/png');

                resolve(dataURL);
              };
              img.onerror = () => {
                if (!isImageOnError) {
                  img.src = '/assets/images/placeholder.jpg';
                }
                isImageOnError = true;
              };
              img.src = item.src;
            })
          : Promise.resolve(item.src)
      )
    );
    images.forEach((item: any, i) => {
      item.src = assets[i];
    });
    el.querySelectorAll('[class*=" liga-"]').forEach((item) => item.remove());
  }
}
