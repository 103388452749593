@if(isThemeApplied()){
<mega-menu
  class="z-10"
  [hideCategories]="layoutData()?.hideCategories"
  [class.!hidden]="layoutData()?.hideHeader"
></mega-menu>
@if(layoutData()?.isCheckoutPage){
<div
  class="sticky top-0 flex items-center justify-center font-syne bg-headerBg text-headerFg -mt-4 -mx-4 p-4 z-30 md:col-span-2 md:row-start-1 md:col-start-1 w-full"
>
  <button
    backButton
    class="md:hidden absolute flex items-center justify-center top-1/2 -translate-y-1/2 left-0 bg-secondaryBtnBg bg-opacity-60 text-secondaryBtnFg rounded-full w-10 h-10"
  >
    <span class="font-iconbz iconbz-chevron-left text-2xl"></span>
  </button>
  <h1 class="text-xl">{{ "checkout.securePayment" | translate }}</h1>
  <img
    src="/assets/icons/ssl.png"
    class="absolute top-1/2 -translate-y-1/2 right-4 h-6"
  />
</div>
}
<router-outlet />
@if(!isNavigationEnd() && layoutData()?.skeletonLoadingType) {
<skeleton [type]="layoutData()?.skeletonLoadingType"></skeleton>
}
<!--  -->
@if(isSmallScreen$ | async){
<sticky-mobile-menu
  [class.!hidden]="layoutData()?.hideStickyBar"
></sticky-mobile-menu>
} @defer {
<footer [class.!hidden]="layoutData()?.hideFooter"></footer>
} }
<!--  -->
@if(showCookieMsgComp()){
<cookie-message (onClose)="showCookieMsgComp.set(false)"></cookie-message>
}
<!--  -->
@if(showLoading$ | async){ @if(env.tenantName === 'Biletzero'){
<div
  class="fixed inset-0 bg-overlayBg/70 flex items-center justify-center"
  style="z-index: 9999"
>
  <svg
    class="h-24 w-24"
    width="70"
    height="84"
    viewBox="0 0 70 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="grey"
      d="M51.6965 66.959H53.1965V65.459V46.5H60.3577C61.006 50.4333 64.1616 53.5157 68.1241 54.106V81.7383H1.5V54.2033C5.96187 54.0583 9.63799 50.7801 10.3438 46.5H16.4322V65.459V66.959H17.9322H51.6965Z"
      fill="#DFFF00"
      stroke="#6C22A6"
      stroke-width="4"
    />
    <path
      class="blue"
      d="M17.9322 16.2794H16.4322V17.7794V36.7383H10.3438C9.63778 32.4545 5.95757 29.1799 1.5 29.035V1.5H68.1241V29.1322C64.1584 29.7227 61.0058 32.8081 60.3578 36.7383H53.1965V17.7794V16.2794H51.6965H17.9322Z"
      fill="#DFFF00"
      stroke="#6C22A6"
      stroke-width="4"
    />
  </svg>
</div>
} @else{
<div
  class="fixed inset-0 bg-overlayBg/70 flex items-center justify-center"
  style="z-index: 9999"
>
  <span class="loader"></span>
</div>
} }
