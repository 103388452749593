@if (type === 'detail'){
<div
  class="flex flex-col md:grid md:grid-cols-[1fr_3fr] md:grid-rows-[auto_1fr] w-full gap-6 pb-4 min-h-svh"
>
  <div
    class="animate-pulse w-[calc(100%_+_2rem)] md:w-full h-full mr-3 md:mr-9 md:row-span-2 -mx-4 md:mx-0"
  >
    <div
      class="flex items-center justify-center w-full aspect-video md:aspect-[1/2] bg-gray-300 dark:bg-gray-700"
    >
      <svg
        class="w-10 h-10 text-gray-200 dark:text-gray-600"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 18"
      >
        <path
          d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
        />
      </svg>
    </div>
  </div>
  <div class="animate-pulse w-full hidden md:block h-5">
    <div class="bg-gray-300 dark:bg-gray-700 w-1/2 h-5 mt-4"></div>
  </div>
  <div class="animate-pulse flex flex-col gap-6">
    <div class="flex flex-col gap-1">
      <div class="bg-gray-300 dark:bg-gray-700 w-1/3 h-7"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-1/4 h-5 mt-1"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-1/5 h-5"></div>
    </div>
    <div class="flex flex-col gap-3">
      <div class="bg-gray-300 dark:bg-gray-700 w-1/5 h-6"></div>
      <div class="flex gap-4 items-center">
        <div
          class="flex items-center justify-center w-9 h-9 aspect-square rounded-full overflow-hidden"
        >
          <svg
            class="w-12 h-12 text-gray-200 dark:text-gray-600 rounded-full"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 18"
          >
            <path
              d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
            />
          </svg>
        </div>
        <div class="bg-gray-300 dark:bg-gray-700 w-1/5 h-6"></div>
      </div>
    </div>
    <div class="flex flex-col gap-1">
      <div class="bg-gray-300 dark:bg-gray-700 w-1/3 h-6"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-full h-4 mt-1"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-full h-4"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-1/5 h-4"></div>
    </div>
    <div class="flex flex-col gap-1">
      <div class="bg-gray-300 dark:bg-gray-700 w-1/3 h-6"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-full h-4 mt-1"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-full h-4"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-full h-4"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-full h-4"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-[4rem] h-4"></div>
    </div>
    <div class="flex flex-col gap-3">
      <div class="bg-gray-300 dark:bg-gray-700 w-1/5 h-6"></div>
      <div class="flex gap-4 items-center">
        <div
          class="flex items-center justify-center w-9 h-9 aspect-square rounded-full overflow-hidden"
        >
          <svg
            class="w-12 h-12 text-gray-200 dark:text-gray-600 rounded-full"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 18"
          >
            <path
              d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
            />
          </svg>
        </div>
        <div class="bg-gray-300 dark:bg-gray-700 w-1/5 h-6"></div>
      </div>
    </div>
    <div class="flex flex-col gap-3">
      <div class="flex gap-4 items-center">
        <div
          class="flex items-center justify-center w-full h-full aspect-video overflow-hidden bg-gray-300 dark:bg-gray-700"
        >
          <svg
            class="w-12 h-12 text-gray-200 dark:text-gray-600 rounded-full"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 18"
          >
            <path
              d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</div>
} @else if (type === 'listWithTitle') {
<div
  class="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4 w-full animate-pulse min-h-svh"
>
  <!--  -->
  <div class="flex flex-col gap-1 md:col-span-2">
    <div class="bg-gray-300 dark:bg-gray-700 w-full h-6"></div>
    <div class="bg-gray-300 dark:bg-gray-700 w-1/2 h-6 md:hidden"></div>
  </div>
  <div class="flex flex-col gap-1 md:col-span-2">
    <div class="bg-gray-300 dark:bg-gray-700 w-3/4 h-5 ml-auto"></div>
  </div>
  @for (item of listArray; track $index) {
  <div role="status" class="flex flex-col gap-0.5 w-full h-auto aspect-square">
    <div
      class="flex items-center justify-center w-full h-full aspect-square bg-gray-300 dark:bg-gray-700"
    >
      <svg
        class="w-10 h-10 text-gray-200 dark:text-gray-600"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 18"
      >
        <path
          d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
        />
      </svg>
    </div>
    <div class="bg-gray-300 dark:bg-gray-700 w-1/2 h-4"></div>

    <div class="bg-gray-300 dark:bg-gray-700 w-3/4 h-6"></div>

    <div class="bg-gray-300 dark:bg-gray-700 w-1/2 h-4"></div>
  </div>
  }
  <!--  -->
</div>
} @else if (type === 'placeDetail' || type === 'orgDetail') {
<div
  class="flex flex-col md:grid md:grid-cols-[1fr_2fr] md:grid-rows-[auto_auto_1fr_auto_auto] w-full gap-6 pb-4 min-h-svh md:mt-6"
>
  <div
    class="animate-pulse w-[calc(100%_+_2rem)] md:w-full h-full mr-3 md:mr-9 md:row-span-1 -mx-4 md:mx-0"
  >
    <div
      class="flex items-center justify-center w-full aspect-square bg-gray-300 dark:bg-gray-700"
    >
      <svg
        class="w-10 h-10 text-gray-200 dark:text-gray-600"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 18"
      >
        <path
          d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
        />
      </svg>
    </div>
  </div>
  <div class="animate-pulse flex flex-col gap-6">
    <div class="flex flex-col gap-1">
      <div class="bg-gray-300 dark:bg-gray-700 w-1/3 h-7"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-1/4 h-5 mt-1"></div>
    </div>

    <div class="flex flex-col gap-1">
      <div class="bg-gray-300 dark:bg-gray-700 w-1/3 h-6"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-full h-4 mt-1"></div>
    </div>
  </div>
  <div class="flex overflow-auto col-span-full">
    <div
      class="flex flex-col animate-pulse min-w-[calc(50vw-32px)] md:min-w-[25%] snap-start mr-3 md:mr-9 gap-0.5"
    >
      <div
        class="flex items-center justify-center w-full aspect-square bg-gray-300 dark:bg-gray-700"
      >
        <svg
          class="w-10 h-10 text-gray-200 dark:text-gray-600"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 18"
        >
          <path
            d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
          />
        </svg>
      </div>
      <div class="bg-gray-300 dark:bg-gray-700 w-1/2 h-4"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-3/4 h-6"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-1/2 h-4"></div>
    </div>
    <div
      class="flex flex-col animate-pulse min-w-[calc(50vw-32px)] md:min-w-[25%] snap-start mr-3 md:mr-9 gap-0.5"
    >
      <div
        class="flex items-center justify-center w-full aspect-square bg-gray-300 dark:bg-gray-700"
      >
        <svg
          class="w-10 h-10 text-gray-200 dark:text-gray-600"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 18"
        >
          <path
            d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
          />
        </svg>
      </div>
      <div class="bg-gray-300 dark:bg-gray-700 w-1/2 h-3"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-3/4 h-4"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-1/2 h-3"></div>
    </div>
    <div
      class="flex flex-col animate-pulse min-w-[calc(50vw-32px)] md:min-w-[25%] snap-start mr-3 md:mr-9 gap-0.5"
    >
      <div
        class="flex items-center justify-center w-full aspect-square bg-gray-300 dark:bg-gray-700"
      >
        <svg
          class="w-10 h-10 text-gray-200 dark:text-gray-600"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 18"
        >
          <path
            d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
          />
        </svg>
      </div>
      <div class="bg-gray-300 dark:bg-gray-700 w-1/2 h-3"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-3/4 h-4"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-1/2 h-3"></div>
    </div>
    <div
      class="flex flex-col animate-pulse min-w-[calc(50vw-32px)] md:min-w-[25%] snap-start mr-3 md:mr-9 gap-0.5"
    >
      <div
        class="flex items-center justify-center w-full aspect-square bg-gray-300 dark:bg-gray-700"
      >
        <svg
          class="w-10 h-10 text-gray-200 dark:text-gray-600"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 18"
        >
          <path
            d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
          />
        </svg>
      </div>
      <div class="bg-gray-300 dark:bg-gray-700 w-1/2 h-3"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-3/4 h-4"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-1/2 h-3"></div>
    </div>
  </div>
  <div class="animate-pulse flex flex-col gap-4 col-span-full">
    <div class="bg-gray-300 dark:bg-gray-700 w-1/6 h-5"></div>
    <div class="flex flex-col gap-3 w-full">
      <div class="flex gap-4 items-center">
        <div
          class="flex items-center justify-center w-full h-full aspect-video overflow-hidden bg-gray-300 dark:bg-gray-700"
        >
          <svg
            class="w-12 h-12 text-gray-200 dark:text-gray-600 rounded-full"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 18"
          >
            <path
              d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</div>
} @else if (type === 'artistDetail') {
<div
  class="flex flex-col md:grid md:grid-cols-[1fr_2fr] md:grid-rows-[auto_auto_1fr_auto_auto] w-full gap-6 pb-4 min-h-svh md:mt-6"
>
  <div
    class="animate-pulse w-[calc(100%_+_2rem)] md:w-full h-full mr-3 md:mr-9 md:row-span-1 -mx-4 md:mx-0"
  >
    <div
      class="flex items-center justify-center w-full aspect-square bg-gray-300 dark:bg-gray-700 md:rounded-full"
    >
      <svg
        class="w-10 h-10 text-gray-200 dark:text-gray-600"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 18"
      >
        <path
          d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
        />
      </svg>
    </div>
  </div>
  <div class="animate-pulse flex flex-col gap-6">
    <div class="flex flex-col gap-1">
      <div class="bg-gray-300 dark:bg-gray-700 w-1/3 h-7"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-1/4 h-5 mt-1"></div>
    </div>

    <div class="flex flex-col gap-1">
      <div class="bg-gray-300 dark:bg-gray-700 w-1/3 h-6"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-full h-4 mt-1"></div>
    </div>
  </div>
  <div class="flex overflow-auto col-span-full">
    <div
      class="flex flex-col animate-pulse min-w-[calc(50vw-32px)] md:min-w-[25%] snap-start mr-3 md:mr-9 gap-0.5"
    >
      <div
        class="flex items-center justify-center w-full aspect-square bg-gray-300 dark:bg-gray-700"
      >
        <svg
          class="w-10 h-10 text-gray-200 dark:text-gray-600"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 18"
        >
          <path
            d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
          />
        </svg>
      </div>
      <div class="bg-gray-300 dark:bg-gray-700 w-1/2 h-4"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-3/4 h-6"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-1/2 h-4"></div>
    </div>
    <div
      class="flex flex-col animate-pulse min-w-[calc(50vw-32px)] md:min-w-[25%] snap-start mr-3 md:mr-9 gap-0.5"
    >
      <div
        class="flex items-center justify-center w-full aspect-square bg-gray-300 dark:bg-gray-700"
      >
        <svg
          class="w-10 h-10 text-gray-200 dark:text-gray-600"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 18"
        >
          <path
            d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
          />
        </svg>
      </div>
      <div class="bg-gray-300 dark:bg-gray-700 w-1/2 h-3"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-3/4 h-4"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-1/2 h-3"></div>
    </div>
    <div
      class="flex flex-col animate-pulse min-w-[calc(50vw-32px)] md:min-w-[25%] snap-start mr-3 md:mr-9 gap-0.5"
    >
      <div
        class="flex items-center justify-center w-full aspect-square bg-gray-300 dark:bg-gray-700"
      >
        <svg
          class="w-10 h-10 text-gray-200 dark:text-gray-600"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 18"
        >
          <path
            d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
          />
        </svg>
      </div>
      <div class="bg-gray-300 dark:bg-gray-700 w-1/2 h-3"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-3/4 h-4"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-1/2 h-3"></div>
    </div>
    <div
      class="flex flex-col animate-pulse min-w-[calc(50vw-32px)] md:min-w-[25%] snap-start mr-3 md:mr-9 gap-0.5"
    >
      <div
        class="flex items-center justify-center w-full aspect-square bg-gray-300 dark:bg-gray-700"
      >
        <svg
          class="w-10 h-10 text-gray-200 dark:text-gray-600"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 18"
        >
          <path
            d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
          />
        </svg>
      </div>
      <div class="bg-gray-300 dark:bg-gray-700 w-1/2 h-3"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-3/4 h-4"></div>
      <div class="bg-gray-300 dark:bg-gray-700 w-1/2 h-3"></div>
    </div>
  </div>
</div>
}
