import {
  Directive,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { startWith, Subscription } from 'rxjs';

@Directive({
  selector: '[validation]',
  standalone: true,
})
export class ValidationDirective implements OnInit, OnDestroy {
  protected translate = inject(TranslateService);
  protected elRef = inject(ElementRef);
  protected control = inject(NgControl);
  protected subs: Subscription;
  protected previousValidation = null;
  constructor() {}
  ngOnInit(): void {
    const translations =
      this.translate.store.translations[this.translate.currentLang]
        .validationMessages;

    let el = this.elRef.nativeElement;
    this.subs = this.control.valueChanges
      .pipe(startWith(this.control.value))
      .subscribe(() => {
        if (this.control.valid) {
          this.previousValidation = null;
          if (this.elRef.nativeElement instanceof HTMLInputElement) {
            el?.setCustomValidity?.('');
          }
          return;
        }
        const validation = Object.keys(this.control.errors || {}).filter(
          (key) => this.control.errors[key]
        )[0];
        if (validation && this.previousValidation !== validation) {
          this.previousValidation = validation;
          el?.setCustomValidity?.(
            (translations[validation]
              ? this.translate.instant(`validationMessages.${validation}`, {
                  value: this.control.errors[validation],
                })
              : null) ||
              (translations[this.control.name]?.[validation]
                ? this.translate.instant(
                    `validationMessages.${this.control.name}.${validation}`,
                    {
                      value: this.control.errors[validation],
                    }
                  )
                : null) ||
              (translations[this.control.name]
                ? this.translate.instant(
                    `validationMessages.${this.control.name}`,
                    {
                      value: this.control.errors[validation],
                    }
                  )
                : this.translate.instant('validationMessages.genericError'))
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
