import { Pipe, PipeTransform } from '@angular/core';
import { StringHelper } from '../helpers/string.helper';

@Pipe({
  name: 'slugify',
  standalone: true,
})
export class SlugifyPipe implements PipeTransform {
  transform(value: Array<string> | string): string | Array<string> {
    return Array.isArray(value)
      ? value.map((item) => StringHelper.slugify(item))
      : StringHelper.slugify(value);
  }
}
