export const environment = {
  production: false,
  apiHost: 'https://apiv2.biletzero.xyz/api/v1/',
  gmapApiKey: 'AIzaSyCyXmnKkkz8EEnYQDg0jUMzHSlItjHmEGs',
  cdn: 'https://cdncloud.biletzero.com/',
  socketUrl: 'https://cardhub.biletzero.xyz',
  socketPath: '/product-stock',
  domain: 'biletzero.xyz',
  refAffStorageTime: 24 * 60 * 60 * 1000,
  salesChannel: 'bzcom',
  multiCityDisabled: false,
  initialCity: 'Türkiye',
  priceColorPalette: [
    '#ebc1c1',
    '#ebd8c1',
    '#e6ebc1',
    '#cdebc1',
    '#c1ebdd',
    '#c1ebe7',
    '#c1e1eb',
    '#c1d0eb',
    '#c8c1eb',
    '#dec1eb',
    '#ebc1e1',
  ],
  paymentMethods: ['creditOrDebit'],
  tenantId: '7c7479f6-3d0f-4c73-8595-c6d3e5d695ab',
  tenantName: 'Biletzero',
  enablePatrnerShip: true,
  googleLoginEnabled: true,
  firebaseapp: {
    apiKey: 'AIzaSyD-G7yPVMX7iovz2k2yP1RtOgy8bM0yEtY',
    authDomain: 'biletzero-test.firebaseapp.com',
    projectId: 'biletzero-test',
    storageBucket: 'biletzero-test.firebasestorage.app',
    messagingSenderId: '198517764474',
    appId: '1:198517764474:web:0c3e677c90640e6470a6c3',
    measurementId: 'G-6K9TCJN9G7',
  },
};
