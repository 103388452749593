<img
  ngSrc="/assets/images/coming-soon.jpg"
  [width]="1200"
  [height]="2134"
  priority
/>
<div
  class="flex flex-col absolute top-[calc(min(100vw,_1200px)*0.875)] text-secondaryBtnFg font-syne font-extrabold uppercase w-full"
>
  <div
    class="text-center text-[calc(min(100vw,_1200px)/5)] leading-[1.25] overflow-hidden"
  >
    <span class="block animate-slide-up">{{ "compart1" | translate }}</span>
  </div>
  <div
    class="text-center text-[calc(min(100vw,_1200px)/9)] leading-none overflow-hidden"
  >
    <span class="block animate-slide-up">{{ "compart2" | translate }}</span>
  </div>
  <div
    class="text-center font-dmSans font-normal mt-9 text-sm px-8 normal-case"
  >
    {{ "comingSoon." + activatedRoute.snapshot.data["descKey"] | translate }}
  </div>
</div>
