import { Component, Input } from '@angular/core';

@Component({
  selector: 'skeleton',
  standalone: true,
  imports: [],
  templateUrl: './skeleton.component.html',
  styleUrl: './skeleton.component.scss',
})
export class SkeletonComponent {
  @Input() type = 'detail';
  public listArray = new Array(20).fill(1);
}
