import { HttpInterceptorFn } from '@angular/common/http';
import { environment } from '../environments/environment';

export const salesChannelInterceptor: HttpInterceptorFn = (request, next) => {
  if (request.url.startsWith(environment.apiHost)) {
    const newReq = request.clone({
      headers: request.headers.set('Sales-Channel', environment.salesChannel),
    });

    return next(newReq);
  }
  return next(request);
};
