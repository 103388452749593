export class StringHelper {
  public static slugify(str: string) {
    return (
      (str || '')
        .replace(/ı/g, 'i')
        // split an accented letter in the base letter and the acent
        .normalize('NFD')
        // remove all previously split acents
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        // remove all chars not letters, dashes, numbers and spaces
        .replace(/[^a-z0-9\s\-\./]/g, '')
        .trim()
        .replace(/\s+/g, '-')
        .replace(/\-+/g, '-')
        .replace(/\.+/g, '.')
        .replace(/^\-+|\-+$/g, '')
        .replace(/^\.+|\.+$/g, '')
    );
  }

  public static capitalizeFirstLetter(inputString) {
    return inputString.replace(/^(.)/, (match: string) =>
      match.toLocaleUpperCase('tr-TR')
    );
  }

  public static convertTimeToMs(time: string): number {
    const [hours, minutes, seconds] = time.split(':');
    return +hours * 60 * 60 * 1000 + +minutes * 60 * 1000 + +seconds * 1000;
  }
  public static formatTime(ms: number): string {
    const totalSeconds = Math.floor(ms / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    return `${StringHelper.pad(minutes)}:${StringHelper.pad(seconds)}`;
  }
  public static pad(value: number): string {
    return value.toString().padStart(2, '0');
  }

  public static generateGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (char) {
        const random = (Math.random() * 16) | 0; // Generate a random number (0-15)
        const value = char === 'x' ? random : (random & 0x3) | 0x8; // Adjust for 'y' positions
        return value.toString(16); // Convert to hexadecimal
      }
    );
  }
}
