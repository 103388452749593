import { Routes } from '@angular/router';
import { LayoutComponent } from '../modules/layout/layout.component';
import { seoResolver } from '../resolvers/seo.resolver';
import { AuthGuard } from '../guards/auth.guard';
import { onlyGuestGuard } from '../guards/only-guest.guard';
import { ComingSoonComponent } from '../pages/coming-soon/coming-soon.component';
import { layoutGuard } from '../guards/layout.guard';
import { eventDetailWithIdResolver } from '../resolvers/event-detail-with-id.resolver';
import { orderDetailResolver } from '../resolvers/order-detail.resolver';
import { checkoutCardGuard } from '../guards/checkout-card.guard';
import { isUserPhoneVerifiedGuard } from '../guards/is-user-phone-verified.guard';
import { channelGuard } from '../guards/channel.guard';
import { themeResolver } from '../resolvers/theme.resolver';
import { GuestOrderComponent } from '../pages/guest-order/guest-order.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    resolve: {
      robots: seoResolver,
      themeConfig: themeResolver,
    },
    runGuardsAndResolvers: 'always',
    canActivate: [isUserPhoneVerifiedGuard, layoutGuard, channelGuard],
    children: [
      {
        path: '',
        canActivate: [layoutGuard],
        loadChildren: () => import('../pages/home/home.routes'),
      },
      {
        path: 'explore',
        canActivate: [layoutGuard],
        loadChildren: () => import('../pages/explore/explore.routes'),
      },
      {
        path: 'events',
        canActivate: [layoutGuard],
        loadChildren: () => import('../pages/events/events.routes'),
      },
      {
        path: 'places',
        canActivate: [layoutGuard],
        loadChildren: () => import('../pages/places/places.routes'),
      },
      {
        path: 'artists',
        canActivate: [layoutGuard],
        loadChildren: () => import('../pages/artists/artists.routes'),
      },
      {
        path: 'organizators',
        canActivate: [layoutGuard],
        loadChildren: () => import('../pages/organizators/organizators.routes'),
      },

      {
        path: 'buyTicket/:eventId',
        data: {
          hideStickyBar: false,
          hideCategories: false,
        },
        canActivate: [layoutGuard],
        loadChildren: () => import('../pages/buy-ticket/buy-ticket.routes'),
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'checkout/:eventId',
        data: {
          hideHeader: true,
          hideStickyBar: true,
          hideCategories: true,
          hideFooter: true,
          isCheckoutPage: true,
        },
        runGuardsAndResolvers: 'pathParamsChange',
        canActivate: [checkoutCardGuard, layoutGuard],
        resolve: {
          detail: eventDetailWithIdResolver,
        },
        loadComponent: () =>
          import('../pages/checkout/checkout.component').then(
            (m) => m.CheckoutComponent
          ),
      },
      {
        path: 'paymentSuccess/:orderId',
        data: {
          hideHeader: false,
          hideStickyBar: false,
          hideCategories: false,
          hideFooter: false,
        },

        canActivate: [layoutGuard],
        resolve: {
          order: orderDetailResolver,
        },
        loadComponent: () =>
          import('../pages/payment-success/payment-success.component').then(
            (m) => m.PaymentSuccessComponent
          ),
      },
      {
        path: 'profile',
        canActivate: [AuthGuard, layoutGuard],
        canActivateChild: [AuthGuard],
        loadChildren: () => import('../pages/profile/profile.routes'),
      },
      {
        path: 'legalPages',
        canActivate: [layoutGuard],
        loadChildren: () => import('../pages/contracts/contracts.routes'),
      },
    ],
  },
  {
    path: 'signIn',
    data: {
      hideHeader: false,
      hideStickyBar: false,
      hideCategories: true,
    },
    resolve: {
      robots: seoResolver,
      themeConfig: themeResolver,
    },
    canActivate: [
      onlyGuestGuard,
      layoutGuard,
      isUserPhoneVerifiedGuard,
      channelGuard,
    ],
    canActivateChild: [onlyGuestGuard],
    loadComponent: () =>
      import('../pages/sign-in/sign-in.component').then(
        (m) => m.SignInComponent
      ),
  },
  {
    path: 'register',
    data: {
      hideHeader: false,
      hideStickyBar: false,
      hideCategories: true,
    },
    resolve: {
      robots: seoResolver,
      themeConfig: themeResolver,
    },
    canActivate: [onlyGuestGuard, layoutGuard, channelGuard],
    canActivateChild: [onlyGuestGuard],
    loadComponent: () =>
      import('../pages/register/register.component').then(
        (m) => m.RegisterComponent
      ),
  },
  {
    path: 'forgotPassword',
    data: {
      hideHeader: false,
      hideStickyBar: false,
      hideCategories: true,
    },
    canActivate: [
      onlyGuestGuard,
      layoutGuard,
      isUserPhoneVerifiedGuard,
      channelGuard,
    ],
    resolve: {
      robots: seoResolver,
      themeConfig: themeResolver,
    },
    canActivateChild: [onlyGuestGuard],
    loadComponent: () =>
      import('../pages/forgot-password/forgot-password.component').then(
        (m) => m.ForgotPasswordComponent
      ),
  },
  {
    path: 'guestOrder',
    data: {},
    resolve: {
      robots: seoResolver,
      themeConfig: themeResolver,
    },
    canActivate: [layoutGuard, isUserPhoneVerifiedGuard, channelGuard],
    component: GuestOrderComponent,
  },
  {
    path: 'wallet',
    data: { hideCategories: true, descKey: 'wallet' },
    canActivate: [layoutGuard, isUserPhoneVerifiedGuard, channelGuard],
    component: ComingSoonComponent,
    resolve: {
      robots: seoResolver,
      themeConfig: themeResolver,
    },
  },
  { path: '**', pathMatch: 'full', redirectTo: '/' },
];
