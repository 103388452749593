import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { environment } from '../environments/environment';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  protected http = inject(HttpClient);
  protected guestUserInfo = signal(null);

  getOrders(): Observable<Array<any>> {
    return this.http.get(`${environment.apiHost}Order`).pipe(
      map((response: any) => response.data),
      catchError(() => of([]))
    );
  }

  getDetail(id: string): Observable<any> {
    return this.http
      .get(`${environment.apiHost}Order/${id}`)
      .pipe(map((response: any) => response.data));
  }

  getGuestOrderDetail(data: { identity: string; pnr: string }) {
    return this.http
      .post(`${environment.apiHost}Order/identity`, data)
      .pipe(map((response: any) => response.data));
  }

  getGuestUserInfo(): { identity: string; pnr: string } {
    return this.guestUserInfo();
  }

  setGuestUserInfo(info: { identity: string; pnr: string }) {
    this.guestUserInfo.set(info);
  }

  clearGuestUSerInfo() {
    this.guestUserInfo.set(null);
  }
}
