import { ResolveFn } from '@angular/router';
import { environment } from '../environments/environment';
import { inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { JsonLdService } from '../services/json-ld.service';
import { DOCUMENT } from '@angular/common';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';

export const seoResolver: ResolveFn<boolean> = (route, state) => {
  const metaService = inject(Meta);
  const titleService = inject(Title);
  const jsonLdService = inject(JsonLdService);
  const localizeRoterService = inject(LocalizeRouterService);
  const translateService = inject(TranslateService);
  const doc = inject(DOCUMENT);
  doc.querySelectorAll('meta')?.forEach((item) => item.remove());
  metaService.addTag({
    name: 'robots',
    content:
      !environment.production ||
      state.url
        .split('/')
        .some(
          (item) => item === localizeRoterService.translateRoute('checkout')
        )
        ? 'noindex, nofollow'
        : 'index, follow',
  });
  if (
    state.url === '/' ||
    state.url === '/tr' ||
    state.url.match(/^\/[a-z]{2}$/)
  ) {
    titleService.setTitle(environment.tenantName);
    metaService.updateTag({
      name: 'description',
      value: translateService.instant('seoDesc'),
    });
    metaService.updateTag({
      name: 'og:description',
      value: translateService.instant('seoDesc'),
    });
  }
  jsonLdService.removeJsonLd();
  let link = doc.querySelector('link[rel="canonical"]');
  if (!link) {
    link = doc.createElement('link');
    link.setAttribute('rel', `canonical`);
    doc.head.append(link);
  }
  link.setAttribute(
    'href',
    `https://${location.host}${state.url.split('?')[0]}`
  );
  return true;
};
