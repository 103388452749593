import { inject, Injectable, signal } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, map, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  protected http = inject(HttpClient);
  protected cookieService = inject(CookieService);
  public themeApplied = signal(false);
  getThemeConfig() {
    const themeConfig = this.cookieService.get('themeConfig');
    return themeConfig
      ? of(JSON.parse(themeConfig))
      : this.http.get(`${environment.apiHost}Tenant/theme-config`).pipe(
          map((res: any) => {
            const date = new Date();
            date.setHours(date.getHours() + 8);
            this.cookieService.set(
              'themeConfig',
              JSON.stringify(res?.data || {}),
              date
            );
            return res?.data;
          }),
          catchError(() => of({}))
        );
  }
}
