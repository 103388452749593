export const inValidTicketNumberRules = (basket) => {
  const entries = basket?.cardEntryDtos || [];
  const groupedEntries = entries.reduce((acc, curr) => {
    const minDiff = curr.minumumOrderQuantity - curr.quantity;
    if (!acc[curr.productId]) {
      const diff = Math.abs(curr.ticketNumber - curr.quantity);
      acc[curr.productId] = {
        ...curr,
        totalTicketNumber: curr.quantity,
        visibleRequiredTicketCount: Math.max(
          (diff <= 0 ? 0 : diff) % curr.ticketNumber,
          curr.ticketNumber
        ),
        requiredTicketCount: (diff <= 0 ? 0 : diff) % curr.ticketNumber,
      };
    } else {
      const totalTicketNumber =
        acc[curr.productId].totalTicketNumber + curr.quantity;
      acc[curr.productId].totalTicketNumber = totalTicketNumber;
      const diff = Math.abs(curr.ticketNumber - totalTicketNumber);
      acc[curr.productId].visibleRequiredTicketCount = Math.max(
        (diff <= 0 ? 0 : diff) % curr.ticketNumber,
        curr.ticketNumber
      );
      acc[curr.productId].requiredTicketCount =
        (diff <= 0 ? 0 : diff) % curr.ticketNumber;
    }
    acc[curr.productId].minReqTicketCount = minDiff <= 0 ? 0 : minDiff;
    return acc;
  }, {});
  return Object.values(groupedEntries).filter(
    (entry: any) => entry.requiredTicketCount || entry.minReqTicketCount
  );
};
